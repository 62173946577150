import React, { Component } from 'react';
import { connect } from 'react-redux';
import './TagKeywordManage.scss';
import { emitter } from "../../utils/emitter";

import * as tagKeywordService from "../../services/tagKeywordService";
import * as uploadService from "../../services/uploadService";
import * as messageService from "../../services/messageService"
import { ReactNotifications } from 'react-notifications-component';

import DataTable from 'react-data-table-component';
import moment from 'moment';
import * as constant from '../../../src/utils/constant'
import TagKeywordModal from './TagKeywordModal';
import * as supplierService from "../../services/supplierService";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

class TagKeywordManage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            filterModel: {
                searchText: '',
                supplierId: null,
                pageNum: 1,
                pageSize: 10,
            },
            isOpenModal: false,
            totalCount: 0,
            selectedOption: null, // Option được chọn
            options: [
             ],
            
        }

    }

    componentDidMount() {
        this.getKeyword();
        this.getSuppliers();
    }
    onChangeInput = (event) => {
        let copyState = { ...this.state.filterModel };
        copyState.searchText = event.target.value;
        this.setState({
            filterModel: copyState
        }, () => {
            this.getKeyword();
        })
    }
    handleChange = selectedOption => {
        let copyState = { ...this.state.filterModel };
        copyState.supplierId = selectedOption.value;
        this.setState({
            filterModel: copyState
        }, () => {
            this.getKeyword();
        })
    };
   
    getKeyword = async () => {
        let res = await tagKeywordService.getListKey(this.state.filterModel);
        if (res == 0) {
            messageService.messageNotify('', 'Đã có lỗi xảy ra, vui lòng thử lại!', 'warning');
            return;
        }
        else if (res.isSuccess) {
            let copyState = { ...this.state.listData };
            copyState = res.data;
            this.setState({
                listData: copyState,
                totalCount: res.data[0] ? res.data[0].totalCount : 0
            })
        }
        else {
            messageService.messageNotify('', res.message, 'warning');
            return;
        }
    }

    getSuppliersId = async (id) => {
        let res = await supplierService.getSupplierById(id);
        if (res == 0) {
            messageService.messageNotify('', 'Đã có lỗi xảy ra, vui lòng thử lại!', 'warning');
            return;
        }
        else if (res.isSuccess) {
            if(res.data != null){
                const option = { value: res.data.id, label: res.data.name };
                this.setState({ selectedOption: option ,
                                 options: [option] });
            }
           
        }
        else {
            messageService.messageNotify('', res.message, 'warning');
            return;
        }
    }

    getSuppliers = async () => {
        this.state.filterModel.pageSize = 30;
        let res = await supplierService.getListSupplier(this.state.filterModel);
        if (res == 0) {
            messageService.messageNotify('', 'Đã có lỗi xảy ra, vui lòng thử lại!', 'warning');
            return;
        }
        else if (res.isSuccess) {
            const defaultOption = { value: null, label: "Chọn nguồn" };
            const options = res.data.map(supplier => ({
                value: supplier.id,
                label: supplier.name,
            }));
            options.unshift(defaultOption);
            this.setState({ options });
        }
        else {
            messageService.messageNotify('', res.message, 'warning');
            return;
        }
    }
    toggleNewModal = () => {
        this.setState({
            isOpenModal: !this.state.isOpenModal
        })
    }
    onPageChange = (pageSize, pageNum) => {
        this.state.filterModel.pageNum = pageNum;
        if (pageSize != 0) this.state.filterModel.pageSize = pageSize;
        this.getKeyword();
    }
    openModal(data) {
        if (!data) {
            emitter.emit("CLEAR_DATA_MODAL");
            this.setState({
                isOpenModal: true,
                titleModal: 'Thêm mới từ khóa',
                isAdd: true
            }, () => {
            })

        }
        else {
         //   this.getSuppliersId(data.supplierId)
            emitter.emit("DATA_MODAL", data);
           
            this.setState({
                isOpenModal: true,
                titleModal: 'Sửa từ khóa',
                isAdd: false

            })
        }
    }
    save = async (data) => {
        let res;
        if (this.state.isAdd) {
            res = await tagKeywordService.create(data);
        }
        else {
            res = await tagKeywordService.update(data);
        }
        if (res == 0) {
            messageService.messageNotify('', 'Đã có lỗi xảy ra, vui lòng thử lại!', 'warning');
            return;
        }
        else if (res.isSuccess) {
            this.getKeyword();
            this.setState({
                isOpenModal: false
            })
            messageService.messageNotify('', 'Cập nhật thành công', 'success');
            emitter.emit("CLEAR_DATA_MODAL");
        }
        else {
            messageService.messageNotify('', res.message, 'warning');
            return;
        }
        
        
    }
    checkSuccess = (res) => {

        if (res == 0) {
            messageService.messageNotify('', 'Đã có lỗi xảy ra, vui lòng thử lại!', 'warning');
            return;
        }
        else if (res.isSuccess) {
            this.getKeyword();
            this.setState({
                isOpenModal: false
            })
            messageService.messageNotify('', 'Cập nhật thành công', 'success');
            emitter.emit("CLEAR_DATA_MODAL");
        }
        else {
            messageService.messageNotify('', res.message, 'warning');
            return;
        }
    }
    deleteData = async (data) => {
        let res = await tagKeywordService.deleteNew(data);
        if (res == 0) {
            messageService.messageNotify('', 'Đã có lỗi xảy ra, vui lòng thử lại!', 'warning');
            return;
        }
        else if (res.isSuccess) {
            this.getKeyword();
            messageService.messageNotify('', 'Xóa thành công', 'success');
        }
        else {
            messageService.messageNotify('', res.message, 'warning');
            return;
        }
    }
    render() {
        const paginationComponentOptions = {
            rowsPerPageText: "Số dòng",
            rangeSeparatorText: "của"
        };
        let listData = this.state.listData
        const columns = [
            {
                name: 'STT.',
                selector: (row, index) => index + 1,
            },
            {
                name: 'Tên',
                selector: row => row.name,
            },
            {
                name: 'Nguồn',
                selector: row => row.supplierName,
            },
            {
                name: 'Ngày tạo',
                selector: row => row.createdWhen,
                format: (row) => moment(row.createdWhen).format('HH:mm  DD/MM/yyyy'),
            },
            {
                name: 'Action',
                selector: rows => rows,
                cell: (row) => {
                    return (
                        <div>
                            <a onClick={() => this.openModal(row)}
                                className="btn btn-primary shadow btn-xs sharp me-1"><i className="fas fa-pencil-alt"
                                ></i></a>
                            <a onClick={() => this.deleteData(row)}
                                className="btn btn-danger shadow btn-xs sharp"><i className="fas fa-trash-alt"
                                ></i></a>
                        </div>
                    )
                },
            },
        ];
        return (
            <div className="main-content">
                <TagKeywordModal
                    isOpenModal={this.state.isOpenModal}
                    toggleFromParent={this.toggleNewModal}
                    titleModal={this.state.titleModal}
                    save={this.save}
                />
                <ReactNotifications />
                <div className="page-content">
                    <div className="content-body">
                        <div className="container-fluid">
                            <div className="page-titles">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a >Quản lý từ khóa</a></li>
                                        </ol>
                                    </div>
                                    <div className='col-lg-3'>
                                    <Dropdown
                                    options={this.state.options} // Truyền danh sách các option vào
                                    value={this.state.selectedOption} // Truyền option được chọn vào
                                    onChange={this.handleChange} // Sự kiện khi option được chọn thay đổi
                                    placeholder="Chọn nguồn" // Placeholder cho dropdown
                                />
                                    </div>
                                    <div className='col-lg-2'>
                                        <input type="text" className="form-control" placeholder="Tìm kiếm..."
                                            onChange={(event) => this.onChangeInput(event)}
                                            value={this.state.filterModel.searchText} />
                                    </div>
                                    <div className='col-lg-3'>
                                        <a
                                            style={{ float: 'right' }}
                                            onClick={() => this.openModal()}
                                            className="btn btn-success shadow btn-xs sharp me-1"><i className="fas fa-plus"
                                            ></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-xl-12 col-lg-12">
                                    <div className='card'>
                                        <div className="card-body">
                                            <DataTable
                                                className='dartTable'
                                                data-tag="allowRowEvents"
                                                columns={columns}
                                                data={listData}
                                                striped={true}
                                                noDataComponent={"Không tìm thấy dữ liệu"}

                                                pagination
                                                paginationComponentOptions={paginationComponentOptions}
                                                paginationServer={true}
                                                paginationTotalRows={this.state.totalCount}
                                                paginationRowsPerPageOptions={[10, 20, 50, 100]}
                                                onChangePage={(page) => {
                                                    this.onPageChange(0, page)
                                                }}
                                                onChangeRowsPerPage={(pageSize, pageNum) => {
                                                    this.onPageChange(pageSize, pageNum)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(TagKeywordManage);
