import React, { Component } from 'react';
import { connect } from "react-redux";
import { Redirect, Route, Switch } from 'react-router-dom';
import User from '../containers/User/User';
import Dashboard from '../containers/Dashboard/Dashboard';
import Menu from '../containers/Menu/Menu';
import Header from '../containers/Header/Header';
import { userIsAuthenticated, userIsNotAuthenticated } from '../hoc/authentication';
import ClientManage from '../containers/ClientManage/ClientManage';
import SupplierManage from '../containers/SupplierManage/SupplierManage';
import PaymentHistory from '../containers/PaymentHistory/PaymentHistory';
import DowloadHistory from '../containers/DowloadHistory/DowloadHistory';
import UserManage from '../containers/UserManage/UserManage';
import NewManage from '../containers/NewManage/NewManage';
import BalanceManage from '../containers/BalanceManage/BalanceManage';
import NotiManage from '../containers/NotiManager/NotiManage';
import TagKeywordManage from '../containers/TagKeywordManage/TagKeywordManage';

class System extends Component {
    render() {
        const { systemMenuPath } = this.props;
        return (
            <div className="system-container">
                <div className="system-list">
            <Header />
            <Menu />
                    <Switch>
                        <Route path="/system/dashboard" component={Dashboard} />
                        <Route path="/system/client-manager" component={userIsAuthenticated(ClientManage)} />
                        <Route path="/system/supplier-manager" component={userIsAuthenticated(SupplierManage)} />
                        <Route path="/system/user-manager" component={userIsAuthenticated(UserManage)} />
                        <Route path="/system/new-manager" component={userIsAuthenticated(NewManage)} />
                        <Route path="/system/tagkeyword-manager" component={userIsAuthenticated(TagKeywordManage)} />
                        <Route path="/system/payment-history" component={userIsAuthenticated(PaymentHistory)} />
                        <Route path="/system/dowload-history" component={userIsAuthenticated(DowloadHistory)} />
                        <Route path="/system/balance-manager" component={userIsAuthenticated(BalanceManage)} />
                        <Route path="/system/noti-manager" component={userIsAuthenticated(NotiManage)} />
                        <Route component={() => { return (<Redirect to={systemMenuPath} />) }} />
                    </Switch>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        systemMenuPath: state.app.systemMenuPath
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(System);
