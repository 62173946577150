import React, { Component } from 'react';
import { emitter } from "../../utils/emitter";
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import * as messageService from "../../services/messageService"
import * as uploadService from "../../services/uploadService";
import * as constant from '../../utils/constant'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import * as supplierService from "../../services/supplierService";



class TagKeywordModal extends Component {
    fooRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            code: '',
            name: '',
            supplierId: 0,
            selectedOption: null, // Option được chọn
            options: [
             ],
             filterModel: {
                searchText: '',
                pageNum: 1,
                pageSize: 10,
            },
          
        }
        this.listenEmitter();
    }
   
    componentDidUpdate () {
    //     setTimeout(
    //         function () {
    //             if (this.state.fileName && this.fooRef.current) {
    //                 let fileName = this.state.fileName.slice(this.state.fileName.lastIndexOf("/") + 1,this.state.fileName.search(".png"))
    //                 if (fileName.search(".") == -1){
    //                     fileName = fileName + ".png"
    //                 }
    //                 let file = new File([""], fileName, { type: "image/jpeg", lastModified: new Date().getTime() }, 'utf-8');
    //                 let container = new DataTransfer();
    //                 container.items.add(file);
    //                 document.querySelector('#file_input').files = container.files;
    //             }
    //             else {
    //                 document.querySelector('#file_input').files = null;
    //             }
    //         }
    //             .bind(this),
    //         100
    //     );
 }
    createFileList = (file) => {
        let fileList = new FileList();
        fileList[0] = file;
        fileList.item = index => fileList[index]; // override method functionality
        return fileList;
    }
    listenEmitter = () => {
        emitter.on("CLEAR_DATA_MODAL", () => {
            this.setState({
                id: 0,
                code: '',
                name: '', 
                supplierId: 0
            })
            this.getSuppliers();
        })
        emitter.on("DATA_MODAL", async (data) => {
            let copyState = { ...this.state };
            copyState = data;
            this.setState({
                ...copyState
            })
            this.getSuppliersId(data.supplierId)
            
        })
    }

    getSuppliersId = async (id) => {
        let res = await supplierService.getSupplierById(id);
        if (res == 0) {
            messageService.messageNotify('', 'Đã có lỗi xảy ra, vui lòng thử lại!', 'warning');
            return;
        }
        else if (res.isSuccess) {
            if(res.data != null){
                const option = { value: res.data.id, label: res.data.name };
                this.setState({ selectedOption: option 
                                  });
            }
           
        }
        else {
            messageService.messageNotify('', res.message, 'warning');
            return;
        }
    }
    componentDidMount() {
        if(this.state.supplierId == 0 ){
            this.getSuppliers();
         }
        //else{
        //     this.getSuppliersId(this.state.supplierId);
           
        // }
      
    }
    // getSuppliersId = async (id) => {
    //     let res = await supplierService.getSupplierById(id);
    //     if (res == 0) {
    //         messageService.messageNotify('', 'Đã có lỗi xảy ra, vui lòng thử lại!', 'warning');
    //         return;
    //     }
    //     else if (res.isSuccess) {
    //         if(res.data != null){
    //             const option = { value: res.data.id, label: res.data.name };
    //             this.setState({ selectedOption: option });
    //         }
           
    //     }
    //     else {
    //         messageService.messageNotify('', res.message, 'warning');
    //         return;
    //     }
    // }

    getSuppliers = async () => {
        this.state.filterModel.pageSize = 30;
        let res = await supplierService.getListSupplier(this.state.filterModel);
        if (res == 0) {
            messageService.messageNotify('', 'Đã có lỗi xảy ra, vui lòng thử lại!', 'warning');
            return;
        }
        else if (res.isSuccess) {
            const options = res.data.map(supplier => ({
                value: supplier.id,
                label: supplier.name,
            }));
            this.setState({ options });
        }
        else {
            messageService.messageNotify('', res.message, 'warning');
            return;
        }
    }
    handleChange = selectedOption => {
        this.setState({ 
            selectedOption,
            supplierId: selectedOption.value,
         });
    };

    handleChangeChk = (event, id) => {
        let copyState = { ...this.state };
        copyState[id] = event.target.checked;
        this.setState({
            ...copyState
        })
    }
    onChangeInput = (event, id) => {
        let copyState = { ...this.state };
        copyState[id] = event.target.value;
        this.setState({
            ...copyState
        })

    const inputValue = event.target.value;
    let slugValue = this.state.code; 

    if (id === "name") {
        slugValue = inputValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        slugValue = slugValue.toLowerCase().replace(/\s+/g, "-");
        slugValue = slugValue.replace(/[^\w-]+/g, "");
    }
    
    this.setState({
        [id]: inputValue,
        code: slugValue 
    });
    }
    handleImg = (event) => {
        if (event.target.files[0]) {
            this.setState({
                file: event.target.files[0]
            })
        }
        else {
            this.setState({
                file: null
            })
        }
    }
    toggle = () => {
        this.props.toggleFromParent();
    }
    onSubmit = async () => {
        if (!this.isValidData()) return;
        this.setState({
            code: this.state.code
        },
            () => {
                this.props.save(this.state);
            })
    }
    isValidData = () => {
        let arr = ["name"]
        let arrS = ["tên"]
        for (let i = 0; i < arr.length; i++) {
            if (!this.state[arr[i]]) {
                messageService.messageNotify('', ('Vui lòng nhập ' + arrS[i]), 'warning');
                return false;
            }
        }
        return true;
    }
    render() {

        return (
            <>
                <Modal
                    isOpen={this.props.isOpenModal}
                    toggle={() => { this.toggle() }}
                    className={"modal-lg"}
                >
                    <ModalHeader toggle={() => { this.toggle() }}>{this.props.titleModal}</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="mb-1 col-md-6">
                                <label className="form-label">Tên</label>
                                <input type="text" className="form-control" placeholder="nhập tên"
                                    onChange={(event) => this.onChangeInput(event, "name")}
                                    value={this.state.name} />
                            </div>
                            <div className="mb-1 col-md-6">
                            <label className="form-label">Nguồn</label>
                                <Dropdown
                                    options={this.state.options} // Truyền danh sách các option vào
                                    value={this.state.selectedOption} // Truyền option được chọn vào
                                    onChange={this.handleChange} // Sự kiện khi option được chọn thay đổi
                                    placeholder="Chọn nguồn" // Placeholder cho dropdown
                                />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button className='btn-xs' onClick={() => this.onSubmit()} color="primary" >
                            <i className="fas fa-save"></i> Lưu
                        </Button>{' '}
                        <Button className='btn-xs' onClick={() => this.toggle()} color="danger" >
                            <i className="fas fa-close"></i>  Hủy
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(TagKeywordModal);
