import axios from '../axios';


export const update = async (user) => {
    try {
        var res = await axios.post(`/TagKeyword/Update`, user)
        return res;
    } catch (error) {
        return 0;
    }
};
export const create = async (user) => {
    try {
        var res = await axios.post(`/TagKeyword/Create`, user)
        return res;
    } catch (error) {
        return 0;
    }
};
export const getListKey = async (filterModel) => {
    try {
        var res = await axios.get(`/TagKeyword/GetListTagKeyword`, {
            params: {
                searchText: filterModel.searchText,
                supplierId: filterModel.supplierId,
                pageNum: filterModel.pageNum,
                pageSize: filterModel.pageSize,
            }
        })
        return res;
    } catch (error) {
        return 0;
    }
};
export const deleteNew = async (model) => {
    try {
        var res = await axios.post(`/TagKeyword/Delete`, model)
        return res;
    } catch (error) {
        return 0;
    }
};
