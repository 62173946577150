import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as actions from "../../store/actions";
import { push } from "connected-react-router";
import './Menu.scss';
import { Link } from 'react-router-dom';
import * as userService from "../../services/userService"
import { NavLink } from 'react-router-dom';

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowUser: false,
            user: {
                email: "",
                name: "",
                phoneNumber: "",
                isSA: false
            }
        }
    }
    componentDidMount() {
        this.getCurrentUser();
    }
    getCurrentUser = async () => {
        let res = await userService.getCurrentInfo();
        if (res == 0) {
            return;
        }
        else if (res.isSuccess) {
            let copyState = { ...this.state.user };
            copyState = res.data;
            this.setState({
                user: copyState
            })
        }
        else {
            return;
        }
    }
    showInfoUser = () => {
        this.setState({
            isShowUser: !this.state.isShowUser
        })
    }
    render() {
        const { processLogout } = this.props;

        return (
            <div className="app-menu navbar-menu">
                <div className="navbar-brand-box">
                    <a href="#" className="logo logo-dark">
                        <span className="logo-sm">
                            <img src="../../assets/images/logo-sm.png" alt="" height="22" />
                        </span>
                        <span className="logo-lg">
                            <img src="../../assets/images/logo-dark.png" alt="" height="40" />
                        </span>
                    </a>
                    <a href="#" className="logo logo-light">
                        <span className="logo-sm">
                            <img src="../../assets/images/logo-sm.png" alt="" height="22" />
                        </span>
                        <span className="logo-lg">
                            <img src="../../assets/images/logo-dark.png" alt="" height="40" />
                        </span>
                    </a>
                    <button type="button" className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
                        id="vertical-hover">
                        <i className="ri-record-circle-line"></i>
                    </button>
                </div>

                <div id="scrollbar">
                    <div className="container-fluid">

                        <div id="two-column-menu">
                        </div>
                        <ul className="navbar-nav" id="navbar-nav">
                            <li className="menu-title"><span data-key="t-menu">ADMIN QUẢN LÝ</span></li>
                            <li className="nav-item">
                                <NavLink className="nav-link menu-link" to="/system/client-manager" activeClassName="active">
                                    <i className="ri-dashboard-2-line"></i> <span data-key="t-dashboards">Quản lý khách hàng</span>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link menu-link" to="/system/payment-history" activeClassName="active">
                                    <i className="ri-dashboard-2-line"></i> <span data-key="t-dashboards">Lịch sử nạp</span>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link menu-link" to="/system/dowload-history" activeClassName="active">
                                    <i className="ri-dashboard-2-line"></i> <span data-key="t-dashboards">Lịch sử tải</span>
                                </NavLink>
                            </li>
                            {this.state.user.isSA && <li className="menu-title"><span data-key="t-menu">SUPER ADMIN</span></li>}
                            {this.state.user.isSA && <li className="nav-item">
                                <NavLink className="nav-link menu-link" to="/system/user-manager" activeClassName="active">
                                    <i className="ri-apps-2-line"></i> <span data-key="t-apps">Quản lý tài khoản</span>
                                </NavLink>
                            </li>}
                            {this.state.user.isSA && <li className="nav-item">
                                <NavLink className="nav-link menu-link" to="/system/supplier-manager" activeClassName="active">
                                    <i className="ri-apps-2-line"></i> <span data-key="t-apps">Quản lý nguồn</span>
                                </NavLink>
                            </li>}
                            {this.state.user.isSA && <li className="nav-item">
                                <NavLink className="nav-link menu-link" to="/system/new-manager" activeClassName="active">
                                    <i className="ri-apps-2-line"></i> <span data-key="t-apps">Quản lý tin tức</span>
                                </NavLink>
                            </li>}
                            {this.state.user.isSA && <li className="nav-item">
                                <NavLink className="nav-link menu-link" to="/system/tagkeyword-manager" activeClassName="active">
                                    <i className="ri-apps-2-line"></i> <span data-key="t-apps">Quản lý từ khóa</span>
                                </NavLink>
                            </li>}
                            {this.state.user.isSA && <li className="nav-item">
                                <NavLink className="nav-link menu-link" to="/system/balance-manager" activeClassName="active">
                                    <i className="ri-apps-2-line"></i> <span data-key="t-apps">Xem số dư</span>
                                </NavLink>
                            </li>}
                            {this.state.user.isSA && <li className="nav-item">
                                <NavLink className="nav-link menu-link" to="/system/noti-manager" activeClassName="active">
                                    <i className="ri-apps-2-line"></i> <span data-key="t-apps">Quản lý thông báo</span>
                                </NavLink>
                            </li>}
                        </ul>
                    </div>
                </div>

                <div className="sidebar-background"></div>
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.user.isLoggedIn
    };
};

const mapDispatchToProps = dispatch => {
    return {
        navigate: (path) => dispatch(push(path)),
        processLogout: () => dispatch(actions.processLogout()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
