import axios from '../axios';

export const update = async (model) => {
    try {
        var res = await axios.post(`/Supplier/Update`, model)
        return res;
    } catch (error) {
        return 0;
    }
};
export const getListSupplier = async (filterModel) => {
    try {
        var res = await axios.get(`/Supplier/GetListSupplier`, {
            params: {
                searchText: filterModel.searchText,
                pageNum: filterModel.pageNum,
                pageSize: filterModel.pageSize,
            }
        })
        return res;
    } catch (error) {
        return 0;
    }
};
export const getSupplierById = async (_id) => {
    try {
        var res = await axios.get(`/Supplier/Get?id=`+_id)
        return res;
    } catch (error) {
        return 0;
    }
};
export const getListBalance = async (filterModel) => {
    try {
        var res = await axios.get(`/Supplier/GetBalance`, {
            params: {
                searchText: filterModel.searchText,
                pageNum: filterModel.pageNum,
                pageSize: filterModel.pageSize,
            }
        })
        return res;
    } catch (error) {
        return 0;
    }
};
